import React from "react"
import { Container, Row, Col } from 'reactstrap'

import style from "./Photos.module.css"

const Photos = () => (
  <section id="services" className={`${style.sectionPhotos}`}>
    <Container fluid>
      <Row className={`align-items-center`}>
        <Col lg="3" className="ml-auto" style={{ marginTop: "30px" }}>
          <div className={`${style.card} ${style.photo1}`}>
            <div className={`${style.cardContent}`}>
            </div>
          </div>
        </Col>
        <Col lg="3" style={{ marginTop: "30px" }}>
          <div className={`${style.card} ${style.photo2}`}>
            <div className={`${style.cardContent}`}>
            </div>
          </div>
        </Col>
        <Col lg="3" className="mr-auto" style={{ marginTop: "30px" }}>
          <div className={`${style.card} ${style.photo3}`}>
            <div className={`${style.cardContent}`}>
            </div>
          </div>
        </Col>
      </Row>
      <Row className={`align-items-center`}>
        <Col lg="3" className="ml-auto" style={{ marginTop: "30px" }}>
          <div className={`${style.card} ${style.photo4}`}>
            <div className={`${style.cardContent}`}>
            </div>
          </div>
        </Col>
        <Col lg="3" className="mr-auto" style={{ marginTop: "30px" }}>
          <div className={`${style.card} ${style.photo5}`}>
            <div className={`${style.cardContent}`}>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
)

export default Photos
