import React from "react"
import { Container, Row, Col } from 'react-bootstrap'

import style from "./Mtc.module.css"


const Mtc = () => {
  
  return (
    <section id="mtc" className={`${style.sectionMtc}`}>
      <Container>
        <Row className="align-items-center">
          <Col md="6" className="mb-auto">
            <h3>O que é a Medicina Tradicional Chinesa</h3>
            <div dangerouslySetInnerHTML={(()=>{
              return {__html: `
              <div>A Medicina Tradicional Chinesa (MTC) é uma forma de terapia que estimula o organismo a curar-se por si próprio através de estímulos específicos.
                Os antigos terapeutas chinesas perceberam que para se ter um corpo saudável é necessário ter um espírito são, 
              uma vez que ambos estão inseparavelmente relacionados.</div><br>
              <div>Foi com base neste princípio que a MTC, que existe há mais de 5000 anos é conhecida como a terapia mais antiga do mundo. 
              Na China a medicina ocidental tem o mesmo estatuto que a medicina tradicional chinesa.</div><br>
              <div>Hoje em dia a Medicina Tradicional Chinesa é
              recomendada pela Organização Mundial de Saúde (OMS)
              e reconhecida pela Administração Central do Sistema de
              Saúde (ACSS).</div>
              `}
            })()}></div>
          </Col>
          <Col md="6" className="mb-auto">
          <h3>Como são vistas as doenças na MTC</h3>
            <div dangerouslySetInnerHTML={(()=>{
              return {__html: `
              <div>Na MTC a doença é entendida de um ponto de vista holístico. 
              O órgão doente é examinado e avaliado na sua relação com o todo.
              Na MTC a questão central não é o tratamento das doenças, mas sim o
              tratamento do doente com o auxílio das correções do seu desequilíbrio
              energético.</div><br>
              <div>O objetivo da MTC é equilibrar os distúrbios encontrados no paciente e
              a partir dai, estimular a capacidade do corpo se curar por si só.
              Apesar de este conceito parecer ilógico, a sua concepção está correta
              e tem sido muito útil na prática. Este é um dos segredos do êxito da MTC.</div>`}
            })()}></div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Mtc
