import React from "react"
import { Container, Row, Col } from 'reactstrap'

import style from "./Showcase.module.css"

const Showcase = () => (
  <section id="showcase" className={`${style.sectionShowcase}`}>
    <Container fluid>
      <Row>
        <Col md="3" className="ml-auto mb-auto">
          <div className={`${style.card} ${style.acunpuncture}`}>
            <div className={`${style.cardContent}`}>
            </div>
          </div>
        </Col>
        <Col md="7" className="mr-auto mb-auto" style={{padding:"20px"}}>
        <h3>Acupuntura</h3>
          <div dangerouslySetInnerHTML={(()=>{
            return {__html: `
            <div>O termo Acupuntura designa o ato de inserir
            agulhas muito finas na superfície corporal, estimulando pontos
            específicos com fins terapêuticos. Existem ao todo 361 pontos clássicos de acupunctura,
            relacionados uns com os outros através do sistema de meridianos
            (também conhecidos como “condutos” ou canais de energia). A principal função desta prática terapêutica é equilibrar e
            regular a circulação da energia no corpo.</div><br>
            <div>O tratamento é indolor, podendo o paciente por vezes
            sentir ligeiras sensações de formigueiro ou choque.</div><br>
            <div>Uma sessão dura em média 20 minutos depois de aplicar as
            agulhas. Durante as sessões de acupuntura por vezes são
            utilizadas outras técnicas (aplicação de ventosas,
            electroestimulação, moxabustão e aplicação de calor através de
            uma lâmpada de TDP).</div><br>
            <div>Para os adultos que não suportam agulhas, assim como as
            criança existe a possibilidade de realizar sessões de acupuntura a
            laser. Nestes tratamentos o estimulo é conseguido
            energeticamente através do raio laser.</div>
            `}
          })()}></div>
        </Col>
      </Row>
      <hr style={{ marginTop:"50px",marginBottom:"50px" }}></hr>
      <Row>
        <Col md="7" className="ml-auto mb-auto" style={{padding:"20px"}}>
        <h3>Fitoterapia</h3>
          <div dangerouslySetInnerHTML={(()=>{
            return {__html: `
            <div>A fitoterapia é o ramo mais antigo da ciência médica
            e desempenha um papel extremamente importante,
            principalmente em patologias crónicas.</div><br>
            <div>Esta terapêutica trata e previne da falta de saúde –
            mental, física e emocional – através da utilização de
            plantas medicinais chinesas, simples ou compostas em
            formúlas.</div><br>
            <div>Existem mais de 2000 plantas medicinais, mas só cerca
            de 300 é que são utilizadas.</div><br>
            <div>Como especialista em Medicina Tradicional Chinesa só
            recomendo Fitoterapias simples e fórmulas de
            laboratórios que não usam plantas que demonstraram
            interagir com medicamentos convencionais.</div>
            <div>A Fitoterapia é fundamental na maioria dos tratamentos,
            servindo para complementar em profundidade a
            acupunctura.</div>
            `}
          })()}></div>
        </Col>
        <Col md="3" className="mr-auto mb-auto">
          <div className={`${style.card} ${style.phytotherapy}`}>
            <div className={`${style.cardContent}`}>
            </div>
          </div>
        </Col>
      </Row>
      <hr style={{ marginTop:"50px",marginBottom:"50px" }}></hr>
      <Row>
        <Col md="3" className="ml-auto">
          <div className={`${style.card} ${style.qigong}`}>
            <div className={`${style.cardContent}`}>
            </div>
          </div>
        </Col>
        <Col md="7" className="mr-auto" style={{padding:"20px"}}>
          <h3>Qi Gong</h3>
          <div dangerouslySetInnerHTML={(()=>{
              return {__html: `
              <div>Exercícios de meditação relacionados com à respiração e
              a circulação de energia, contribuindo assim para o
              reequilíbrio do organismo. Os exercícios de Qi Gong são considerados
              simultaneamente métodos profiláticos para a manutenção
              da saúde e formas de intervenção para recuperá-la.</div><br>
              <div>Existem exercícios estáticos, exercícios de movimento,
              exercícios respiratórios e exercícios de meditação.</div><br>
              <div>Quando realizados de forma regular contribuem para
              alcançar a paz interior e a serenidade.</div><br>
              <div>No Qi Gong tudo é realizado muito devagar, com a calma
              necessária.</div><br>
              <div>A medicina chinesa acredita que a doença é uma
              manifestação de uma desarmonia e fraqueza do sistema
              de energia do indivíduo. Logo se o sistema de energia é
              forte e equilibrado, não há doença.</div><br>
              <div>Esta é uma prática segura, sendo ideal para pessoas de
              qualquer idade e em qualquer nível de condição física.</div>
              `}
            })()}></div>
        </Col>
      </Row>
      <hr style={{ marginTop:"50px",marginBottom:"50px" }}></hr>
      <Row>
        <Col md="7" className="ml-auto mb-auto" style={{padding:"20px"}}>
        <h3>Tui Na</h3>
          <div dangerouslySetInnerHTML={(()=>{
            return {__html: `
            <div>O Tui Na é uma das especialidades da MTC, que utiliza
            as mãos do terapeuta (técnicas manuais) como
            ferramenta para tratar doenças.</div><br>
            <div>O Tui Na contribui para regular a circulação de energia,
            sangue e líquidos orgânicos no corpo do paciente.</div><br>
            <div>Em determinados quadros clínicos vai proporcionar um
            alívio imediato na sintomatologia apresentada pelo
            paciente ( por exemplo em dores de costas).</div><br>
            <div>Esta é uma terapia segura e eficaz, além de contribuir na
            promoção da longevidade e bem estar, apresenta uma
            mais valia de não apresentar efeitos colaterais aos
            pacientes.s</div>
            `}
          })()}></div>
        </Col>
        <Col md="3" className="mr-auto mb-auto">
          <div className={`${style.card} ${style.tuina}`}>
            <div className={`${style.cardContent}`}>
            </div>
          </div>
        </Col>
      </Row>
      <hr style={{ marginTop:"50px",marginBottom:"50px" }}></hr>
      <Row>
        <Col md="3" className="ml-auto">
          <div className={`${style.card} ${style.home}`}>
            <div className={`${style.cardContent}`}>
            </div>
          </div>
        </Col>
        <Col md="7" className="mr-auto" style={{padding:"20px"}}>
          <h3>Domicilios</h3>
          <div dangerouslySetInnerHTML={(()=>{
              return {__html: `
              <div>São vários os pacientes que preferem ser atendidos no
              conforto do seu lar. É possível solicitar junto do Dr. Leonel
              Duarte a realização de consultas e tratamentos em
              regime de domicílio.</div><br>
              <div>Se estiver interessado solicite por mensagem privada as
              condições para requerer este serviço.</div>
              `}
            })()}></div>
        </Col>
      </Row>
    </Container>
  </section>
)

export default Showcase
