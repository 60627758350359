import React from "react"
import { Container, Row, Col, Button } from "reactstrap"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStaticQuery, graphql } from "gatsby"
// import mapboxgl from "mapbox-gl"

import style from "./Contact.module.css"

library.add(fab)

const Contact = () => {
  const data = useStaticQuery(graphql`
    query SiteSocialQuery {
      site {
        siteMetadata {
          socialMedia {
            href
            id
          }
        }
      }
    }
  `)

  const social = data.site.siteMetadata.socialMedia

  // useEffect(() => {
  //   mapboxgl.accessToken =
  //     "pk.eyJ1IjoiZHNsYWdhIiwiYSI6ImNrZWZ2ZGY0MzFnYnAyc21pZHVlaGtqYzQifQ.Qhi0xudvyGxtMYPZU94r0A"
  //   var mapi = new mapboxgl.Map({
  //     container: "map",
  //     style: "mapbox://styles/mapbox/streets-v11",
  //     center: [-8.616045, 41.259135],
  //     zoom: 15,
  //   });

  //   var geojson = {
  //     type: "FeatureCollection",
  //     features: [
  //       {
  //         type: "Feature",
  //         geometry: {
  //           type: "Point",
  //           coordinates: [-8.616045, 41.259135],
  //         },
  //         properties: {
  //           title: "Clinica",
  //           description: "Leonel Duarte",
  //         },
  //       }
  //     ],
  //   }

  //     // create a HTML element for each feature
  //     var el = document.createElement("div")
  //     el.className = "marker"

  //     // make a marker for each feature and add to the map
  //     new mapboxgl.Marker(el).setLngLat(geojson.features[0].geometry.coordinates).addTo(mapi)
  // }, [])

  return (
    <section id="contact" className={`${style.sectionContact}`}>
      <Container fluid>
        <Row className="align-items-center">
          {/* <Col md="5" className="ml-auto mb-auto">
            <div id="map" className={`${style.map}`}></div>
          </Col> */}
          <Col
            md="6"
            className={`${style.contactDetails} ml-auto mr-auto mb-auto mt-auto`}
          >
            <h3 style={{ textAlign: "center" }}>Contactos</h3>
            <div className={`${style.social}`}>
              {social.map((link, index) => (
                <a
                  key={index}
                  href={link.href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={["fab", `${link.id}`]} />
                </a>
              ))}
            </div>
            <h4 className={`${style.email}`}>
              leonelduarte.medicinachinesa@gmail.com
            </h4>
            <a href="mailto:leonelduarte.medicinachinesa@gmail.com">
              <Button style={{ margin: "10px 0 0 0" }} color="primary">
                Contactar
              </Button>
            </a>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Contact
