import React from "react"

import Layout from '../components/Layout/Layout'
import SEO from '../components/SEO/SEO'
import Top from '../components/Top/Top'
import About from '../components/About/About'
import Photos from '../components/Photos/Photos'
import Mtc from '../components/Mtc/Mtc'
import Consultation from '../components/Consultation/Consultation'
import Contact from '../components/Contact/Contact'
import Showcase from '../components/Showcase/Showcase'


const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Top />
    <div className="main mainRaised">
      <About />
      <Mtc />
      <Consultation />
      <Showcase />
      <Photos />
      <Contact />
    </div>
  </Layout>
)

export default IndexPage
