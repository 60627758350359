import React from "react"
import { Container, Row, Col } from 'react-bootstrap'
import style from "./Consultation.module.css"

import Consult from '../../images/consult.jpg'

const Consultation = () => {
  
  return (
    <section id="mtc" className={`${style.sectionConsultation}`}>
      <Container>
        {/* <Row className={`${style.titleServices} align-items-center`}>
          <Col md="12">
            <h2>Serviços</h2>
          </Col>
        </Row> */}
        <Row className="align-items-center">
          <Col md="6" className="mb-auto">
            <h3>Como funciona a Consulta de MTC?</h3>
            <div dangerouslySetInnerHTML={(()=>{
              return {__html: `
              <div>O Dr. Leonel Duarte observa o
              paciente à "luz" das boas práticas da Medicina Tradicional
              Chinesa e escuta as suas principais queixas e sintomas.
              Com base nas informações recolhidas realiza um diagnóstico energético.</div><br>
              <div>A partir dai prescreve o tratamento com
              base na experiência milenar da Medicina Chinesa, assim como na sua experiência pessoal adquirida ao longo de
              quase duas décadas como profissional de saúde (número de sessões de acupunctura, fitoterapia a tomar (fórmulas
              à base de plantas), Tui Na e Qi Gong (exercícios
              energéticos)) para promover a melhoria global do estado
              de saúde do paciente.</div>
              `}
            })()}></div>
            <h3 className={`${style.topSpace}`}>Consulta de reavaliação de MTC</h3>
            <div dangerouslySetInnerHTML={(()=>{
              return {__html: `
              <div>O paciente é acompanhado em todo o
              processo pelo Dr. Leonel Duarte, o que é uma mais valia
              para perceber se a terapêutica escolhida está a resultar
              ou é necessário adaptar a mesma, no entanto é
              fundamental realizar uma consulta de reavaliação a cada
              final de ciclo terapêutico para aferir se as queixas e
              sintomas prevalecem ou se o diagnóstico energético se
              alterou, uma vez que em Medicina Chinesa não existem 2
              casos iguais.</div>
              `}
            })()}></div>
          </Col>
          <Col md="4" className="ml-auto mr-auto">
            <img className={`${style.imageConsult}`} src={Consult} alt="consulta" />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Consultation
