import React from "react"
import { Container, Row, Col } from 'react-bootstrap'

import style from "./About.module.css"

import Leonel from '../../images/leonelduarte.jpg'

const About = () => (
  <section id="about" className={`${style.sectionAbout}`}>
    <Container fluid>
      <Row className={`${style.paddedTop} align-items-center`}>
        <Col xl="3" className={`mr-auto ml-auto`}>
          <img className={`${style.aboutImg}`} src={Leonel} alt="leonel-duarte" />
        </Col>
        <Col xl="6" className={`${style.aboutText} mr-auto ml-auto mb-auto`}>
          <blockquote cite="Antigo Provérbio Chinês"><span className={`${style.innerText}`}>Os sábios não tratam aqueles que já estão doentes, mas sim os que
          ainda estão saudáveis.</span></blockquote>
          <h3>Leonel Duarte</h3>
          {/* <div dangerouslySetInnerHTML={(()=>{
            return {__html: `
            <br><div>Vivemos tempos e vidas altamente automatizadas. A maior parte de
            nós tem vindo a perder a capacidade de observar e/ou analisar a vida e/ou o
            mundo de uma forma global (visão holística).</div><br>
            <div>A medicina ocidental tornou-se excessivamente especializada. Essa
            evolução tem vantagem e desvantagem para a população. Em muitas
            situações tratamentos muito complicados, com inúmeros efeitos secundários.</div><br>
            <div>A Medicina Tradicional Chinesa pode ser utilizada como complemento
            razoável à pratica da medicina ocidental, uma vez que se preocupo com o
            diagnóstico e com o tratamento do corpo como um todo e não apenas com
            alguns sintomas.</div><br>
            <div>Como Especialista em Medicina Tradicional Chinesa pretendo ajuda-lo
            a aproveitar os conhecimentos desta medicina milenar, com o objetivo de os
            poder aplicar a si próprio.</div>
            `}
          })()}></div> */}
          <div dangerouslySetInnerHTML={(()=>{
            return {__html: `
            <br><div>O Dr. Leonel Duarte é um profissional
            de saúde com mais de 7 anos de formação específica e
            qualificada em Medicina Tradicional Chinesa. É detentor
            da Cédula profissional, emitida pela ACSS – responsável
            pela emissão do titulo profissional para as terapêuticas
            não convencionais.</div><br>
            <div>É também associado da Associação Portuguesa dos
            Profissionais de Acupunctura e Medicina Tradicional
            Chinesa (APPA) e membro da Sociedade Científica de
            Medicina Tradicional Chinesa (SCMTC).</div><br>
            <div>O Dr. Leonel Duarte iniciou esta caminhada quando em
            em 2002 após ter concluído recentemente uma
            Licenciatura em Educação Física e Desporto inscreveu-se
            no Curso Superior de Acupuntura e Fitoterapia Tradicional
            Chinesa (IPMTC) e (APA-DA). O Curso foi desenhado e
            orientado pelo Dr. Pedro Choy e teve a duração de 5
            anos.</div><br>
            <div>Após ter concluído o seu estágio e o seu curso começou
            iniciou os seus serviços com terapeuta de Medicina
            Tradicional Chinesa.</div><br>
            <div>Em 2011 ingressou no Mestrado de Medicina Tradicional
            Chinesa pelo ICBAS – Instituto Ciências Abel Salazar –
            Universidade do Porto.</div><br>
            <div>Durante o seu percurso tem participado em várias ações
            de formação, convenções, workshops, seminários,
            colóquios e simpósios nacionais e internacionais
            relacionados com a Medicina Tradicional Chinesa.</div><br>
            <div>Tem também participado em palestras como preletor
            relacionadas com a Medicina Tradicional Chinesa.</div><br>
            `}
          })()}></div>
        </Col>
      </Row>
    </Container>
  </section>
)

export default About
